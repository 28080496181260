import PropTypes from "prop-types";
import React, { useEffect } from "react"
import { Redirect } from "@reach/router"
import * as Stages from "../components/stages"
import { StageContext } from "../contexts/stage"
import { LocationContext } from "../contexts/location"
import * as tracking from "../tracking"
import SEO from '../components/seo'
import { navigate } from 'gatsby'

const RedirectReplacement = ({ url }) => {

  useEffect(() => {
    navigate(url)
  }, [])

  return null;

}

const EffectWrapper = props => {
  return props.children
}

/* 
  getStageFromLocationState(location) 
  gets current stage from the location state (via browser history)

*/

const getStageFromLocationState = location =>
  location && location.state ? location.state.stage : undefined

const MenuStage = () => {

  const stageContext = React.useContext(StageContext)
  const locationContext = React.useContext(LocationContext)

  useEffect(() => {

    if (Stages.stageTextNames[stageContext.stage] !== undefined)
      tracking.trackStageView(Stages.stageTextNames[stageContext.stage])

    }, [stageContext.stage])

  const menuStages = {
    [Stages.LOADING]: null,
    [Stages.INTRO_STAGE]: (
      <Stages.IntroStage nextStage={stageContext.nextStage} />
    ),
    [Stages.START_STAGE]: (
      <Stages.StartStage nextStage={stageContext.nextStage} />
    ),
    [Stages.LOCATION_SELECT]: (
      <Stages.LocationSelect nextStage={stageContext.nextStage} />
    ),
    [Stages.SELECT_INTEREST]: (
      <Stages.InterestSelect
        nextStage={stageContext.nextStage}
        setStage={stageContext.setStage}
      />
    ),
    [Stages.SHOW_EVENTS]: <RedirectReplacement url={`/rewards/${locationContext.location}?events`} />,
    [Stages.SHOW_REWARDS]: (
      <RedirectReplacement url={`/rewards/${locationContext.location}/`} />
    ),
  }

  return (
    <>
      <SEO />
      {menuStages[getStageFromLocationState() || stageContext.stage]}
    </>
  )
}

MenuStage.propTypes = {
  location: PropTypes.any
}

const IndexPage = props => <MenuStage {...props} />

export default IndexPage
